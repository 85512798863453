import { useState } from "react";
import Arrow from "../icons/Arrow";

export default function Experience({
  title,
  items,
  skills
}){

  const [show, setShow] = useState(false)
  return (
    <div className="flex flex-row w-full">
      <div className="bg-black w-1.5" />
      <article className="p-10 border w-full">
        <div className="flex flex-row justify-between">
          <h3 className="font-semibold">
            {title}
          </h3>
          <span className="flex items-center cursor-pointer p-4" onClick={() => setShow((prev) => !prev)}>{show ? 'less' : 'more'}</span>
        </div>
        <div className={!show ? "hidden" : ""}>
          {items.map(item => (
            <div key={item.title}>
              <div className="flex flex-row">
                <div className="w-6 mt-2.5 mr-1">
                  <Arrow />
                </div>
                <p>
                  {item.title}
                </p>
              </div>
              {item.subItems?.length && <ul>
                {item.subItems.map(subItem => (
                  <li key={subItem}>
                  - {subItem}
                </li>
                ))}
              </ul>}
              {skills && (
                <p>
                  {skills}
                </p>
              )}
            </div>
          ))}
        </div>
      </article>
    </div>
  )
}