import { useEffect, useRef, useState } from "react";
import "./App.css";
import Experience from "./components/Experience";
import Git from "./icons/Git";
import Linkedin from "./icons/Linkedin";
import Location from "./icons/Location";
import Internet from "./icons/Site";
import Flow from "./icons/Flow";

function App() {
  const ref = useRef();
  const [path, setPath] = useState("");

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (path && ref.current && !ref.current.contains(e.target)) {
        setPath(null);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [path]);

  return (
    <div
      className="flex flex-col gap-10 w-full pt-40 bg-hero bg-[length:100%_400px] pb-10"
      style={{
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col gap-16 max-w-screen-lg w-full mx-auto px-4 ">
        <div className="flex flex-row justify-center w-full mx-auto ">
          <img
            src="/Guilherme-Furlaneto.jpeg"
            alt="Guilherme Furlaneto"
            className="absolute md:hidden w-32 h-32 rounded-full top-20"
          />
          <div className="flex flex-row justify-between w-full bg-[#f4f4f1] min-h-[400px]">
            <div className="flex flex-col md:justify-between gap-4 md:gap-0.5 text-center md:text-left h-full w-full md:w-auto px-2 pt-16 pb-8 md:p-10">
              <div className="flex flex-col gap-1 px-4 sm:px-8 md:px-0">
                <span className="text-3xl font-bold">
                  Guilherme De Pieri Furlaneto
                </span>
                <span className="text-xl font-semibold">
                  Full stack engenieer
                </span>
                <span>Javascript - NodeJS/ReactJS/NextJs</span>
              </div>
              <div className="flex flex-col gap-2 items-center mt-2 md:items-start px-7 md:px-0 w-fit mx-auto md:ml-0 md:mr-auto h-full w-full">
                <span className="flex flex-row gap-2">
                  <Internet />
                  gpfurlaneto.com
                </span>
                <span className="flex flex-row gap-2">
                  <Location />
                  Florianópolis/SC
                </span>
                <div className="flex flex-row mt-auto justify-between items-center w-full">
                  <div className="flex flex-row items-center gap-4">
                    <a
                      href="https://www.linkedin.com/in/guilhermefurlaneto"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Linkedin />
                    </a>
                    <a
                      href="https://github.com/gpfurlaneto"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Git />
                    </a>
                  </div>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="underline flex flex-row gap-2 justify-center items-center"
                    href="https://flow-app.gpfurlaneto.com"
                  >
                    <Flow />
                    Flow App
                  </a>
                </div>
              </div>
            </div>
            <div className="not-prose hidden md:block">
              <img
                src="/Guilherme-Furlaneto.jpeg"
                alt="Guilherme Furlaneto"
                className="w-[380px] h-full"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 p-10 border">
          <article className="flex flex-col text-sm prose-sm prose-headings:text-gray-700 prose-p:text-gray-700 prose-headings:my-0 prose-h2:my-2 prose-p:my-2 w-full min-w-full max-w-screen-lg mx-auto w-full border-b pb-8">
            <p className="">
              Graduated in Information Systems, I consolidated my career as a Full Stack Developer, working for nine years
              with Java. The Java knowledge provided me great opportunities, and I could experience different kinds of
              projects, financial, agriculture, and public health, where I contributed to the health minister of Brazil.
            </p>
            <p>
              Since 2017, I have decided to face new challenges. Moving to a new stack, I continued working with software
              development, but in this new moment with javascript.
            </p>
            <p>
              With more than 10 years of working in software development, in my last job experiences, I participated in many
              projects with different stacks, blockchain, and other technologies.
            </p>
            <p>
              I embrace the opportunity to learn new things continually. For me, a tech stack is a versatile tool for crafting
              solutions and overcoming challenges. This perspective allows me to remain open-minded and flexible, never
              confined to a single stack. Instead, I enthusiastically seek growth and improvement beyond the confines of any
              specific stack, aiming to become a more skilled and accomplished professional.
            </p>
          </article>
          <a
            target="_blank"
            href="/Guilherme%20De%20Pieri%20Furlaneto%20-%20Resume.pdf"
            className="mx-auto bg-[#006494] px-4 py-3 text-white font-semibold"
          >
            Download PDF
          </a>
        </div>
        <div className="flex flex-col gap-5 text-gray-700 text-sm prose-sm prose-headings:text-gray-700 prose-p:text-gray-700 prose-headings:my-0 prose-h2:my-2 prose-p:my-2 w-full min-w-full">
          <span className="text-3xl mx-auto font-bold">Experiences</span>
          <Experience
            title='(Nov 2023 - Present) - Senior Frontend Developer - Curotec · Full-time'
            items={[
              {
                title: "As a Front End Developer, I contribute to enhancing the company's design system, focusing on refining user interfaces for optimal functionality. Throughout my experience, I've encountered various positive outcomes, particularly learning about accessibility. It means making sure that websites and apps can be used by everyone, including people who might have trouble seeing, hearing, or moving around. By learning about this, I've become better at making sure that what I create can be used by as many people as possible, which makes me feel happy and proud of my work.",
                subItems: [
                  "As a Front End Developer, I help improve the company's design system by improving how user interfaces look and work. I ensure the website or app works well and is easy to use. In my time doing this job, I've learned a lot of good stuff, especially about making things accessible for everyone."
                ]
              }
            ]}
            skills="React.js · Node.js · Front-End Development · Unit Testing · Radix · Jest · Cascading Style Sheets
            (CSS) · storybook · TypeScript · JavaScript · AngularJS · React Hook Form · REST APIs · Git · Docker ·
            Docker Compose."
          />
          <Experience
            title="(Mar 2021 - Nov 2023 · 2 yrs 9 mos) - Full Stack Engineer - AE Studio · Full-time"
            items={[
              {
                title:
                  "Developed an online platform for medical specialists that facilitates professional connections and knowledge sharing through online educational video content",
                subItems: [
                  "Responsible for instructing the team how to work with strapi.",
                  "Responsible for the migration from the legacy application to strapi v4, structuring the database, and defining the strategy to migrate.",
                ],
              },
              {
                title: "Development of Melanie’s Marketplace",
                subItems: [
                  "Developed transactions to buy and sell from different marketplaces and collections in the same application.",
                  "A marketplace in Flow blockchain.",
                ],
              },
              {
                title:
                  "Developed a nonprofit marketplace - Responsible for the entire application development, deployment, and maintenance.",
                subItems: [
                  "Developed using Strapi, Next.js with SSR capabilities, graphql, typescript.",
                  "Responsible for the migration from strapi v3 to strapi v4, evaluating all the possible impacts, and defining the strategy to migrate from mongo to postgres.",
                  "Rest API integrations",
                ],
              },
            ]}
            skills="Skills: React, ReactQuery, Next.js, TypeScript, cascading Style Sheets (CSS), TailwindCss, PostgreSQL, MongoDB, GraphQL, Node.js, Koa.js, REST APIs, Docker, Docker-Compose, Strapi-CMS, Git, Heroku, Blockchain (Flow and Ethereum), Capacitor.js, PWA"
          />
          <Experience
            title="(Dec 2017 - Mar 2021 · 3 yrs 4 mos) - Full Stack Engineer - Let’s Party · Full-time"
            items={[
              {
                title:
                  "Developed applications with microservices through the rest api",
                subItems: [
                  "Integrated different services through the rest api, like gdpr apps service and administration apps service.",
                  "Responsible for instructing the new team members about the software development process.",
                  "Involved entirely in the software development process, development, bug fixes, code review, sprint planning, and others",
                  "I developed a transaction control management with TypeORM, using injections. Saving a lot of time for the developers that don't need to start, neither commit transactions because it is all made by the container.",
                ],
              },
            ]}
            skills="Skills: React, TypeScript, cascading Style Sheets (CSS), MaterialUI, PostgreSQL, MongoDB, Node.js, Koa.js, REST APIs, Docker, Docker-Compose, Git, WebdriverIO."
          />
          <Experience
            title="(Sep 2013 - Dec 2017 · 4 yrs 4 mos) - Full Stack Engineer - Bridge Laboratory · Full-time"
            items={[
              {
                title:
                  "I helped to build the eSus, an Electronic Health Record (EHR) for Brazil’s Unified Health System (SUS) (sisaps.saude.gov.br/esus)",
                subItems: [
                  "Developed the module to register citizens in the application. This module has an integration built using SOAP protocol, to fetch citizens data from the web service of the country.",
                ],
              },
            ]}
            skills="Skills: Java, J2EE, EJB, JPA, Jboss EAP, JUnit, Arquillian, Mockito, Querydsl, Liquibase, Flyway."
          />
          <Experience
            showMore={false}
            title="(2012 - Aug 2013 · 1 yr 8 mos) Analyst/Developer - Hexagon Agriculture · Full-time"
            items={[{ title: "Java developer" }]}
          />
          <Experience
            showMore={false}
            title="(Jul 2009 - Oct 2012 · 3 yrs 4 mos) Systems Analyst - Nexxera Group · Full-time"
            items={[
              {
                title:
                  "Definition of Deadlines; Definition of Schedules; Team coordination; Planning, monitoring and controlling by CMMI and MPSBR Practices;",
              },
            ]}
          />
          <Experience
            showMore={false}
            title="(Feb 2008 - Jun 2009 · 1 yr 4 mos) Software developer - Nexxera Group · Full-time"
            items={[
              {
                title: "Java developer",
              },
            ]}
          />
        </div>
        <div className="flex flex-col gap-5 text-gray-700 text-sm w-full min-w-full">
          <span className="text-3xl mx-auto font-bold">Certificates</span>
          <article className="flex flex-col items-center md:items-start text-sm prose-sm prose-p:text-sm prose-img:my-2 w-full min-w-full">
            <div className="text-center w-fit">
              <h2 className="font-bold mr-auto text-left">2023</h2>
              <span
                className="cursor-pointer"
                onClick={() => setPath("/certified6.png")}
              >
                <img
                  src="/certified6.png"
                  width={300}
                  height={300}
                  alt="Certificate NodeJs 2023"
                />
                NodeJs - Ignite Poogram
              </span>
            </div>
            <div className="text-center w-fit">
              <h2 className="font-bold mr-auto text-left">2022</h2>
              <div className="flex flex-col md:flex-row gap-3">
                <div
                  className="text-center cursor-pointer"
                  onClick={() => setPath("/certified2.png")}
                >
                  <img
                    src="/certified2.png"
                    width={300}
                    height={300}
                    alt="Rocketseat Fundamentar 2022"
                  />
                  Especializar - Discover
                </div>
                <div
                  className="text-center cursor-pointer"
                  onClick={() => setPath("/certified3.png")}
                >
                  <img
                    src="/certified3.png"
                    width={300}
                    height={300}
                    alt="Rocketseat Especializar 2022"
                  />
                  Fundamentar - Discover
                </div>
                <div
                  className="text-center cursor-pointer"
                  onClick={() => setPath("/certified5.png")}
                >
                  <img
                    src="/certified5.png"
                    width={300}
                    height={300}
                    alt="Rocketseat Conectar 2022"
                  />
                  Conectar - Discover
                </div>
              </div>
            </div>
            <div className="text-center w-fit">
              <h2 className="font-bold mr-auto text-left">2020</h2>
              <span
                className="cursor-pointer"
                onClick={() => setPath("/certified1.png")}
              >
                <img
                  src="/certified1.png"
                  width={300}
                  height={300}
                  alt="NodeJs Getting Started 2020"
                />
                NodeJs getting Started - Pluralsight
              </span>
            </div>
            <div className="text-center w-fit ">
              <h2 className="font-bold mr-auto text-left">2017</h2>
              <span
                className="cursor-pointer"
                onClick={() => setPath("/certified4.png")}
              >
                <img
                  src="/certified4.png"
                  width={300}
                  height={300}
                  alt="Introduction to NodeJs + ExpressJS 2017"
                />
                Introduction to NodeJs - Udemy
              </span>
            </div>
          </article>
        </div>
      </div>
      {path && (
        <div
          ref={ref}
          className="fixed m-auto left-0 right-0 w-[800px] max-w-[100vw]"
        >
          <div
            className="flex flex-row justify-center items-center absolute -top-6 -right-6 p-6 bg-black opacity-70 h-10 w-10 rounded-full cursor-pointer"
            onClick={() => setPath(null)}
          >
            <div className="bg-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 50 50"
                fill="#FFF"
              >
                <path
                  fill="#FFF"
                  d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
                ></path>
              </svg>
            </div>
          </div>
          <img
            src={path}
            width="100%"
            height="100%"
            alt="Rocketseat Fundamentar 2022"
          />
        </div>
      )}
    </div>
  );
}

export default App;
